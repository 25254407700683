/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Wrapper, SliceZone, Title, SEO, Header } from '../components'
import { LocaleContext } from '../components/Layout'
import website from '../../config/website'

const Hero = styled.div`
  margin: 0 0 40px 0;
  h1 {
    color: ${props => props.theme.colors.fontDark};
    text-align: center;
    padding: 80px 0 40px 0;
    margin: 0;
    font-size: 36px;
    font-weight: 800;
  }
`

const SinglePageWrapper = styled(Wrapper)`
  flex: 1 0 auto;
`

const SinglePage = ({
  data: {
    post: { data },
  },
  location,
  pageContext: { locale },
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const { body, description, title, keywords } = data
  return (
    <>
      <SEO
        title={`${title.text} ${i18n.defaultTitleAddon}`}
        pathname={location.pathname}
        locale={locale}
        desc={description}
        keywords={keywords}
      />
      <Hero>
        <Wrapper>
          <Header dark location={location} />
          {/*<h1 dangerouslySetInnerHTML={{ __html: title.text }} />*/}
        </Wrapper>
      </Hero>
      <SinglePageWrapper id={website.skipNavId}>
        <SliceZone allSlices={body} />
      </SinglePageWrapper>
    </>
  )
}

export const pageQueryOperatorDevice = graphql`
  query SinglePageById($id: String!, $locale: String!) {
    post: prismicSinglePage(id: { eq: $id }, lang: { eq: $locale }) {
      id
      uid
      data {
        body {
          ... on PrismicSinglePageBodyText {
            slice_type
            primary {
              text {
                raw {
                  text
                  type
                }
              }
            }
          }
        }
        description
        keywords
        title {
          html
          text
        }
      }
    }
  }
`

export default SinglePage

SinglePage.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
